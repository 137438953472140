@import "color";
@mixin border-t-blue{
    border-top:1px solid $line-blue;
}
@mixin border-b-blue{
    border-bottom:1px solid $line-blue;
}
@mixin border-b-gray{
    border-bottom:1px solid $line-gray;
}

$navL-bg-color:#ceeafb;
$navL-slec-color:#438fad;
$navL-gradient-from:#72bdd4;
$navL-gradient-from:#337a96;
$bg-color:#fff;
$line-blue:#3498db;
$line-gray:#ccc;
$icon-table-color:#3f9cec;
$font-blue:#3f9cec;
$bg-gray:#eeebeb;
$border-gray:#ccc;
$border-blue:#3498db;





@import "color";
@import "border";

body{
	font-size: 16px;
}

.left_col {
	background: $navL-bg-color;
}
.nav-sm .container.body .col-md-3.left_col {
	min-height: 100%;
	width: 70px;
	padding: 0;
	z-index: 1001;
	position: absolute;
	
}
.nav-sm .container.body .col-md-3.left_col.menu_fixed {
	position: fixed;
	height: 100%;
}
.nav-sm .container.body .col-md-3.left_col .mCSB_container,
.nav-sm .container.body .col-md-3.left_col .mCustomScrollBox {
	overflow: visible;
}
.nav-sm .hidden-small {
	visibility: hidden;
}
.nav-sm .container.body .right_col {
	padding: 10px 20px;
	margin-left: 70px;
	z-index: 2;
}
.nav-sm .navbar.nav_title {
	width: 70px;
}
.nav-sm .navbar.nav_title a span {
	display: none;
}
.nav-sm .navbar.nav_title a i {
	font-size: 27px;
	margin: 13px 0 0 3px;
}

.main_container{
	padding-top:60px;
}
/* .nav-sm .main_container .top_nav {
	display: block;
	margin-left: 70px;
	z-index: 9999;
*/
.nav-sm .nav.side-menu li a {
	text-align: center !important;
	font-weight: 400;
	font-size: 14px;
	padding: 10px 5px;
}
.nav-sm .nav.child_menu li.active,
.nav-sm .nav.side-menu li.active-sm {
	/* border-right: 5px solid #1ABB9C;*/
}
.nav-sm ul.nav.child_menu ul,
.nav-sm .nav.side-menu li.active-sm ul ul {
		position: static;
		width: 200px;
		background: none;
}
.nav-sm > .nav.side-menu > li.active-sm > a {
	color: #1ABB9C !important;
}
.nav-sm .nav.side-menu li a i.toggle-up {
	display: none !important;
}
.nav-sm .nav.side-menu li a i {
	font-size: 25px !important;
	text-align: center;
	width: 100% !important;
	margin-bottom: 5px;
}
.nav-sm ul.nav.child_menu {
	left: 100%;
	position: absolute;
	top: 0;
	width: 210px;
	z-index: 4000;
	background: #3E5367;
	display: none;
}
.nav-sm ul.nav.child_menu li {
	padding: 0 10px;
}
.nav-sm ul.nav.child_menu li a {
	text-align: left !important;
}
.menu_section {
	margin-bottom: 35px;
}
.menu_section h3 {
	padding-left: 23px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-weight: bold;
	font-size: 11px;
	margin-bottom: 0;
	margin-top: 0;
	text-shadow: 1px 1px #000;
}
.menu_section >ul {
	margin-top: 10px;
}

.main_menu span.fa {
	float: right;
	text-align: center;
	margin-top: 5px;
	font-size: 10px;
	min-width: inherit;
	/* color: #C4CFDA; */
}
.active a span.fa {
	text-align: right !important;
	margin-right: 4px;
}
.nav-sm .menu_section {
	margin: 0;
}
.nav-sm .side-menu span.fa,
.nav-sm .menu_section h3 {
	display: none;
}
.nav-sm li li span.fa {
	display: inline-block;
}
.nav_menu {
	float: left;
	/* background: #fff; */
	border-bottom: 1px solid #D9DEE4;
	/*margin-bottom: 10px;*/
	width: 100%;
	position: relative;
}
@media (min-width: 480px) {
	.nav_menu {
		position: static;
	}
}
.nav-md .container.body .col-md-3.left_col {
	min-height: 100%;
	width: 230px;
	padding: 0;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 1;
}
.nav-md .container.body .col-md-3.left_col.menu_fixed {
	height: 100%;
	position: fixed;
	z-index:1001;
}
body .container.body .right_col {
	background: $bg-color;
}
.nav-md .container.body .right_col {
	padding: 10px 20px 0;
	margin-left: 230px;
}

@media (max-width: 991px) {
	.nav-md .container.body .right_col, .nav-md .container.body .top_nav {
		width: 100%;
		margin: 0;
	}
	.nav-md .container.body .col-md-3.left_col {
		display: none;
	}
	.nav-md .container.body .right_col {
		width: 100%;
		padding-right: 0
	}
}
@media (max-width: 1200px) {
	.x_title h2 {
		width: 62%;
		font-size: 17px;
	}
	.tile, .graph {
		zoom: 85%;
		height: inherit;
	}
}
@media (max-width: 1270px) and (min-width: 192px) {
	.x_title h2 small {
		display: none
	}
}
.left_col .mCSB_scrollTools {
	width: 6px;
}
.left_col .mCSB_dragger {
	max-height: 400px !important;
}
.nav-md .left_col.scroll-view{
	width:100%;
}
/**  ------------------------------------------  **/



.container {
	width: 100%;
	padding: 0
}
.navbar-nav>li>a, .navbar-brand, .navbar-nav>li>a {
	color: #fff !important;
}
.top_nav .nav>li>a:focus, .top_nav .nav>li>a:hover, .top_nav .nav .open>a, .top_nav .nav .open>a:focus, .top_nav .nav .open>a:hover {
	background: #D9DEE4;
}
body {
	min-width: 768px;
}
.main_container .top_nav {
	position:fixed;
	width:100%;
	display: block;
	z-index:1001;
	top:0;
	background-color: $bg-color;
}
.no-padding {
	padding: 0 !important;
}
.page-title {
	width: 100%;
	height: 65px;
	padding: 10px 0;
}
.page-title .title_left {
	width: 45%;
	float: left;
	display: block;
}
.page-title .title_left h3 {
	margin: 9px 0;
}
.page-title .title_right {
	width: 55%;
	float: left;
	display: block;
}
.page-title .title_right .pull-right {
	margin: 10px 0;
}
.fixed_height_320 {
	height: 320px;
}
.fixed_height_390 {
	height: 390px;
}
.fixed_height_200 {
	height: 200px;
}
.overflow_hidden {
	overflow: hidden
}
.progress-bar-dark {
	background-color: #34495E !important;
}
.progress-bar-gray {
	background-color: #BDC3C7 !important;
}
table.no-margin .progress {
	margin-bottom: 0;
}
.main_content {
	padding: 10px 20px;
}
.col-md-55 {
	width: 50%;
	margin-bottom: 10px;
}
@media (min-width: 768px) {
	.col-md-55 {
		width: 20%;
	}
}
@media (min-width: 992px) {
	.col-md-55 {
		width: 20%;
	}
}
@media (min-width: 1200px) {
	.col-md-55 {
		width: 20%;
	}
}
@media (min-width: 192px) and (max-width: 1270px) {
	table.tile_info span.right {
		margin-right: 7px;
		float: left;
	}
}
.center-margin {
	margin: 0 auto;
	float: none!important;
}
.col-md-55, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	position: relative;
	min-height: 1px;
	float: left;
	padding-right: 10px;
	padding-left: 10px;
}
.row {
	margin-right: -10px;
	margin-left: -10px;
}
.grid_slider .col-md-6 {
	padding: 0 40px;
}
h1, .h1, h2, .h2, h3, .h3 {
	margin-top: 10px;
	margin-bottom: 10px;
}
a {
	color: #5A738E;
	text-decoration: none;
}
a, a:visited, a:focus, a:active, :visited, :focus, :active, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: 0;
}
a:hover, a:focus {
	text-decoration: none
}
.navbar {
	margin-bottom: 0
}
.navbar-header {
	background: #34495E;
}
.navbar-right {
	margin-right: 0;
}
.top_nav .navbar-right {
	margin: 0;
	min-width: 200px;
	float: right;
}
.top_nav .navbar-right li {
	display: inline-block;
	float: right;
	position: static;
}
@media (min-width: 480px) {
	.top_nav .navbar-right li {
		position: relative;
	}
}
.top_nav .dropdown-menu li {
	width: 100%;
}
.top_nav .dropdown-menu li a {
	width: 100%;
	padding: 12px 20px;
}
.top_nav li a i {
	font-size: 18px;
}
.navbar-static-top {
	position: fixed;
	top: 0;
	width: 100%;
}
.sidebar-header {
	border-bottom: 0;
	margin-top: 46px;
}
.sidebar-header:first-of-type {
	margin-top: 0
}
.nav.side-menu> li {
	position: relative;
	display: block;
	cursor: pointer;
}
.nav.side-menu> li > a {
	margin-bottom: 6px;
}
.nav.side-menu> li > a:hover {
	/* color: #F2F5F7 !important; */
}
.nav.side-menu>li>a:hover, .nav>li>a:focus {
	text-decoration: none;
	background: transparent;
}
.nav.child_menu {
	display: none;
}
.nav.child_menu li:hover,
.nav.child_menu li.active {
	background-color: rgba(255, 255, 255, 0.06);
}
.nav.child_menu li {
	padding-left: 36px;
}
.nav-md ul.nav.child_menu li:before {
	background: #425668;
	bottom: auto;
	content: "";
	height: 8px;
	left: 23px;
	margin-top: 15px;
	position: absolute;
	right: auto;
	width: 8px;
	z-index: 1;
	border-radius: 50%;
}
.nav-md ul.nav.child_menu li:after {
	border-left: 1px solid #425668;
	bottom: 0;
	content: "";
	left: 27px;
	position: absolute;
	top: 0;
}
.nav-md ul.nav.child_menu li:last-child::after {
	bottom: 50%;
}
.nav.side-menu>li>a, .nav.child_menu>li>a {
	color: #333;
	font-weight: 400;
}
.nav.child_menu li li:hover,
.nav.child_menu li li.active {
	background: none;
}
.nav.child_menu li li a:hover,
.nav.child_menu li li a.active {
	color: #fff;
}
.nav>li>a {
	position: relative;
	display: block;
	padding: 13px 15px 12px;
}
.nav li.current-page {
	background: rgba(255, 255, 255, 0.05);
}
.nav li li li.current-page {
	background: none;
}
.nav li li.current-page a {
	color: #fff;
}
.nav.side-menu> li.active > a {
	color:#fff;
	background-color:$icon-table-color;
	-webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
	        box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
}
.navbar-brand, .navbar-nav>li>a {
	font-weight: 500;
	color: #ECF0F1 !important;
	margin-left: 0 !important;
	line-height: 35px;
}

.nav.navbar-nav>li>a {
	color: #515356 !important;
}
.nav.top_menu>li>a {
	position: relative;
	display: block;
	padding: 10px 15px;
	color: #34495E !important;
}
.nav>li>a:hover, .nav>li>a:focus {
	background-color: transparent;
}
.top_search {
	padding: 0;
}
.top_search .form-control {
	border-right: 0;
	-webkit-box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
	        box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
	border-radius: 25px 0px 0px 25px;
	padding-left: 20px;
	border: 1px solid rgba(221, 226, 232, 0.49);
}
.top_search .form-control:focus {
	border: 1px solid rgba(221, 226, 232, 0.49);
	border-right: 0;
}
.top_search .input-group-btn button {
	border-radius: 0px 25px 25px 0px;
	border: 1px solid rgba(221, 226, 232, 0.49);
	border-left: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	color: #93A2B2;
	margin-bottom: 0 !important;
}
.toggle {
	float: left;
	margin: 0;
	padding-top: 16px;
	width: 70px;
}
.toggle a {
	padding: 15px 15px 0;
	margin: 0;
	cursor: pointer;
}
.toggle a i {
	font-size: 26px;
}
/* nav logo */
.logo {
	float:left;
	line-height: 60px;
	img{
		height:45px;
	}
	span{
		border-left: 1px solid rgb(9, 30, 135);
		padding-left: 10px;
		font-size: 18px;
		font-weight: bold;
		color:rgb(9, 30, 135);
	}
}
/*  /nav logo  */
.nav.child_menu > li > a {
	color: rgba(255, 255, 255, 0.75);
	font-size: 12px;
	padding: 9px;
	background-color: #4d93d0;
}
.panel_toolbox {
	float: right;
	min-width: 70px;
}
.panel_toolbox>li {
	float: left;
	cursor: pointer;
}
.panel_toolbox>li>a {
	padding: 5px;
	color: #C5C7CB;
	font-size: 14px;
}
.panel_toolbox>li>a:hover {
	background: #F5F7FA;
}
.line_30 {
	line-height: 30px;
}
.main_menu_side {
	padding: 0;
}
.bs-docs-sidebar .nav>li>a {
	display: block;
	padding: 4px 6px;
}
footer {
	background: $bg-color;
	padding: 10px 20px;
	display: block;
}

.nav-sm footer {
	margin-left: 70px ;
}
.footer_fixed footer {
	position: fixed;
	left: 0px;
	bottom: 0px;
	width: 100%;
}
@media (min-width: 768px) {
	.footer_fixed footer {
		margin-left: 0;
	}
}
@media (min-width: 768px) {
	.footer_fixed .nav-sm footer {
		margin-left: 0;
	}
}


/* *********  widgets  *************************************** */

ul.msg_list li {
	background: #f7f7f7;
	padding: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 6px 6px 0;
	width: 96% !important;
}
ul.msg_list li:last-child {
	margin-bottom: 6px;
	padding: 10px;
}
ul.msg_list li a {
	padding: 3px 5px !important;
}
ul.msg_list li a .image img {
	border-radius: 2px 2px 2px 2px;
	-webkit-border-radius: 2px 2px 2px 2px;
	float: left;
	margin-right: 10px;
	width: 11%;
}
ul.msg_list li a .time {
	font-size: 11px;
	font-style: italic;
	font-weight: bold;
	position: absolute;
	right: 35px;
}
ul.msg_list li a .message {
	display: block !important;
	font-size: 11px;
}
.dropdown-menu.msg_list span {
	white-space: normal;
}
.dropdown-menu {
	border: medium none;
	-webkit-box-shadow: none;
	        box-shadow: none;
	display: none;
	float: left;
	font-size: 12px;
	left: 0;
	list-style: none outside none;
	padding: 0;
	position: absolute;
	text-shadow: none;
	top: 100%;
	z-index: 1;
	border: 1px solid #D9DEE4;
	border-top-left-radius: 0;
	border-top-right-radius: 0;

}
.dropdown-menu>li>a {
	color: #5A738E;
}
.navbar-nav .open .dropdown-menu {
	position: absolute;
	background: #fff;
	margin-top: 0;
	border: 1px solid #D9DEE4;
	-webkit-box-shadow: none;
	right: 0;
	left: auto;
	width: 220px;
}
.navbar-nav .open .dropdown-menu.msg_list {
	width: 300px;
}
.info-number .badge {
	font-size: 10px;
	font-weight: normal;
	line-height: 13px;
	padding: 2px 6px;
	position: absolute;
	right: 2px;
	top: 8px;
}

.sidebar-footer {
	bottom: 0px;
	clear: both;
	display: block;
	padding: 5px 0 0 0;
	position: fixed;
	width: 230px;
	background: #2A3F54;
}
.sidebar-footer a {
	padding: 7px 0 3px;
	text-align: center;
	width: 25%;
	font-size: 17px;
	display: block;
	float: left;
	background: #172D44;
}
.sidebar-footer a:hover {
	background: #425567;
}
.user-profile img {
	width: 29px;
	height: 29px;
	border-radius: 50%;
	margin-right: 10px;
}
/* *********  /widgets  *************************************** */

/* *********  iconts-display  **************************** */

.main_menu .fa {
	width: 26px;
	opacity: .99;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* *********  /iconts-display  **************************** */

/* ***** buttons ********/
.btn {
	border-radius: 3px;
}
a.btn-success, a.btn-primary, a.btn-warning, a.btn-danger {
	color: #fff;
}
.btn-success {
	background: #26B99A;
	border: 1px solid #169F85;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
	background: #169F85;
}
/* ***** /buttons ********/

/** NProgress **/
#nprogress .bar {
	background: #1ABB9C;
}

#nprogress .peg {
	-webkit-box-shadow: 0 0 10px #1ABB9C, 0 0 5px #1ABB9C;
	        box-shadow: 0 0 10px #1ABB9C, 0 0 5px #1ABB9C;
}

#nprogress .spinner-icon {
	border-top-color: #1ABB9C;
	border-left-color: #1ABB9C;
}
/** /NProgress **/











/** PNotify **/
.alert-success {
	color: #ffffff;
	background-color: rgba(38, 185, 154, 0.88);
	border-color: rgba(38, 185, 154, 0.88);
}

.alert-info {
	color: #E9EDEF;
	background-color: rgba(52, 152, 219, 0.88);
	border-color: rgba(52, 152, 219, 0.88);
}

.alert-warning {
	color: #E9EDEF;
	background-color: rgba(243, 156, 18, 0.88);
	border-color: rgba(243, 156, 18, 0.88);
}

.alert-danger,
.alert-error {
	color: #E9EDEF;
	background-color: rgba(231, 76, 60, 0.88);
	border-color: rgba(231, 76, 60, 0.88);
}

.ui-pnotify.dark .ui-pnotify-container {
	color: #E9EDEF;
	background-color: rgba(52, 73, 94, 0.88);
	border-color: rgba(52, 73, 94, 0.88);
}

.custom-notifications {
	position: fixed;
	margin: 15px;
	right: 0;
	float: right;
	width: 400px;
	z-index: 4000;
	bottom: 0;
}

ul.notifications {
	float: right;
	display: block;
	margin-bottom: 7px;
	padding: 0;
	width: 100%;
}

.notifications li {
	float: right;
	margin: 3px;
	width: 36px;
	-webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
	        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.notifications li:last-child {
	margin-left: 0;
}

.notifications a {
	display: block;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	padding: 9px 8px;
}

.tabbed_notifications .text {
	padding: 5px 15px;
	height: 140px;
	border-radius: 7px;
	-webkit-box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
	        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
}

.tabbed_notifications div p {
	display: inline-block;
}

.tabbed_notifications h2 {
	font-weight: bold;
	text-transform: uppercase;
	width: 80%;
	float: left;
	height: 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
}

.tabbed_notifications .close {
	padding: 5px;
	color: #E9EDEF;
	float: right;
	opacity: 1;
}
/** /PNotify **/

/*2018.12.04*/
/*home.html*/
.fix-size{
	/* max-width:100%;
	min-width:1000px;
	margin:0 auto; */
}
 /* .nav-sm #menu_toggle{
	 display:none;
 } */

.form-self-style {
	.input-group{
		input.btn {
			margin-bottom: 0;
		}
		& > .form-control {
			width: 115px;
			padding: 6px 4px;
		}
		& > .form-control.date-input {
			width: 80px;
		}
		& input.btn+input.btn {
			margin-left: 20px;
		}
		.round-input {
			border-radius: 4px;
		}
		.small {
			width: 50px;
		}
	}
	.form-group{
		width:210px;
		& > label {
			padding-right: 5px;
			font-weight: normal;
			position: relative;
		}
	}
}
span.required{
	position: absolute;
	color:red;
	font-size: 16px;
	left:-10px;
	top:0;
}
.round-input {
	border-radius: 4px;
}
.table-nav {
	margin-top: 10px;
	.table-nav-go{
		padding-top:6px;
		padding-bottom:6px;
		text-align: center;
	}
	input[type="text"]{
		width:40px;
		border-radius: 3px;
		border:1px solid #ddd;
	}
	.pagination{
		margin:0;
	}
	input[type="button"]{
		border: none;
		margin-left: 10px;
	}
}
.table>tbody>tr>td, 
.table>tbody>tr>th, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th{
	padding:0px;
	vertical-align: middle;
	height:40px;
}
.table{
	.iconfont{
		color:$icon-table-color;
		font-size: 25px;
		&:hover{
			color:darken($icon-table-color,20%);
		}
	}
}

@media (min-width: 768px){
	.form-inline .form-group {
			margin-bottom:20px;
	}
}
/*home.html end*/

/* login.html*/
.login-wrap{
	.input-group-addon{
		background-color: #fff;
	}
	background-color: rgb(151, 201, 247);
	padding:100px 0;
}
.login-content{
	margin:0 auto;
}
@media (min-width:768px) {
	.login-wrap h3{
		margin-bottom: 10px;
	}
	.login-content{
		width:1000px;
	}
	.login-form .input-group{
		margin-bottom: 30px;
	}
	.login-form{
		height:328px;
	}
	.login-wrap .login_bg{
		height:328px;
	}	
}
@media (min-width: 1200px) {
	.login-wrap h3{
		margin-bottom: 20px;
	}
	.login-content{
		width:1180px;
	}
	.login-form .input-group{
		margin-bottom: 40px;
	}
	.login-form{
		height:370px;
	}
	.login-wrap .login_bg{
		height:370px;
	}	
}
.img-wrap{
	width:70%;
}
.login-form{
	width:30%;
	padding:20px 30px;
	background-color: rgba(255,255,255,0.8);
	border-radius: 8px;
}
.login-wrap h3{
	color:#337ab7;
}

.login-wrap .login_bg{
	width:90%;
}	

.login-form .glyphicon{
	top:0;
}
@media (max-width:768px) {
	.login-wrap{
		background-image: url('../../production/images/login_bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.login-wrap h3{
		margin-bottom: 10px;
	}
	.login-content{
		max-width: 100%;
	}
	.login-form .input-group{
		margin-bottom: 30px;
	}
	.img-wrap{
		width:60%;
	}
	.login-form{
		width:40%;
		float:none!important;
		height:328px;
		margin:0 auto;
	}
	.login-wrap .login_bg{
		display: none;
	}	
}
.page-suffix{ 
	margin-top: 20px;
	a{
		padding:0 5px;
		border-right: 1px solid;
	}
}
/* login.html end*/

/* add.html */
.step-wrap{
	min-width:1000px ;
	
	.anchor{
		font-size: 16px;
		/* font-weight: bold; */
		&>a{
			span{
				font-size: 20px;
				margin-right: 10px;
			}
			border-radius: 8px;
			&.active{
				cursor:pointer;
				&:hover{
					background-color:darken($icon-table-color,10);
				}
				background-color:$icon-table-color;
			}
			color:#fff;
			display: inline-block;
			padding:0 20px; 
			margin-right: 20px;
			background-color: lighten($icon-table-color,25);
			cursor: not-allowed;
			&.editable{
				cursor:pointer;
				&:hover{
					background-color:darken($icon-table-color,10);
				}
			}
		}
		padding-bottom: 10px;
	}
}
.item-add{
	.classify_slec{
		height:50px;
		line-height: 50px;
		@include border-t-blue;
		@include border-b-blue;
		label{
			font-weight: normal;
			position: relative;
		}
	}
	.user_info{
		@include border-b-blue;
		padding-top:20px;
		padding-bottom:10px;
		label{
			width:70px;
			text-align-last: justify;
			text-align: justify;
			text-justify:distribute-all-lines;/*for IE*/
		}
	}
	.btns{
		padding-top: 10px;
		.btn{
			background-color: $icon-table-color;
			border-color:$icon-table-color;
			&:hover{
				background-color:darken($icon-table-color,10);
			}
		}
	}
}
.area-input{
	width:450px;
	label{
		font-weight: normal;
		position: relative;
	}
	textarea{
		resize:none;
	}
	+.area-input{
		margin-left: 70px;
	}
}
.ill-info{
	@include border-b-blue;
	.row-wrap{
		padding:20px 0;
	}
}
#slice-list{
	.thumbnail{
		width:60px;
		height:60px;
		margin:0;
		padding:0;
	}
	textarea{
		width:120px;
		height:60px;
		resize:none;
		padding:0;
	}
}
button,
.buttons,
.btn,
.modal-footer .btn+.btn {
	margin-bottom: 5px;
	margin-right: 5px;
}
#step-1{
	.form-self-style {
		.input-group{
			input.btn {
				margin-bottom: 0;
			}
			& > .form-control {
				width: 148px;
				padding: 6px 4px;
			}
			& > .form-control.date-input {
				width: 80px;
			}
			& input.btn+input.btn {
				margin-left: 20px;
			}
			.round-input {
				border-radius: 4px;
			}
			.small {
				width: 60px;
				margin-right:10px;
			}
		}
		.form-group{
			width:240px;
			& > label {
				padding-right: 5px;
				font-weight: normal;
				position: relative;
			}
		}
	}
}

/* add.html end */

/* add-1.html add-2.html*/
#step-2{
	.table-responsive{
		overflow: visible;
	}
	.modal-dialog{
		position: relative;
		top:100px;
	}
	.upload-title{
		line-height: 50px;
		height:50px;
		font-size: 16px;
		margin-bottom: 0;
		@include border-t-blue;
		color:$font-blue;
	}
	.active-upload{
		color:#fff;
		margin:10px 10px 20px;
		background-color: $icon-table-color;
		&:hover{
			background-color: darken($icon-table-color,10);
		}
	}
	textarea{
		border:1px solid #ccc;
		margin:5px 0;
		&:focus{
			outline: none !important;
			border:1px solid $icon-table-color;
		}
		border-radius: 4px;
	}
	select{
		border-radius: 4px;
	}
	.form-self-style{
		.input-group-btn{
			width:auto;
			.btn{
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}
		}
		.amplification{
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			width:81px;
		}
		.dropdown-menu{
			/* width:100px; */
			z-index:3;
			li>a{
				padding:0 10px;
				height:30px;
				line-height: 30px;
			}
		}
		.dropdown-menu-right{
			right:5px;
		}
		.open{
			.dropdown-menu{
				min-width: auto;
				width:115px;
			}
		}

	}
}
.delFile{
	cursor:pointer;
}
.blue-line{
	@include border-t-blue;
}
.gray-line{
	@include border-b-gray;
}
.upload-modal-md{
	.file-btn{
		display: inline-block;
		vertical-align: middle;
	}
	.upload-btn{
		color:#fff;
		display: inline-block;
		vertical-align: middle;
		height:40px;
		line-height: 40px;
		border:none;
		border-radius: 4px;
		padding:0 15px;
		&.active{
			background-color: $icon-table-color;
			&:hover{
				background-color: darken($icon-table-color,10);
			}
		}
	}
}
#pre-img{
	text-align: center;
	img{
		max-width:100%;
	}
}
#step-3{
	tr>td:last-child{
		width:300px;
	}
	.search{
		margin:20px 0;
	}
	.doctor_selected{
		margin-bottom: 10px;
		span{
			word-wrap: none;
			color:#fff;
			padding:5px;
			margin-right: 5px;
			background-color:rgb(231,149,28);
		}
		.iconfont{
			margin-left: 5px;
			cursor: pointer;
		}
	}
}
/* add-1.html add-2.html end */

/* medical-record.html  user-center.html*/
.record-padding{
	padding:5px 10px;
}
.fix-center{
	position: relative;
	border:1px solid $line-gray;
	border-radius: 4px;
	font-size:15px;
	width:800px;
	margin:10px auto;
	.fix-center-nav{
		position: absolute;
		// top:70px;
		left:-147px;
		.flag{
			position: relative;
			&+.flag{
				margin-top: 30px;
			}
			&>span{
				display: inline-block;
				width:12px;
				height:12px;
				background-color: $bg-gray;
				border-radius: 50%;
				margin-right: 10px;
			}
			&>a{
				display: inline-block;
				min-width:112px;
				padding:10px 20px;
				border-radius: 4px;
			}
			&.active{
				&>a{
					color:#fff;
					background-color: $icon-table-color;
				}
				&>span{
					background-color: $icon-table-color;
				}
			}
		}
		.connect-line{
			position: absolute;
			height:59px;
			border-left: 1px solid #ccc;
			top:-45px;
			left:5px;
		}
	}
	.record-head{
		background-color: $bg-gray;
		height:45px;
		line-height: 45px;
		.iconfont{
			font-size: 22px;
		}
		span{
			margin:0 10px;
			cursor: pointer;
			&:hover{
				color:$icon-table-color;
			}
		}
	}
	.record-flag{
		color:$icon-table-color;
		font-size: 16px;
		cursor: auto !important;;
	}
	
	.record-content{
		position: relative;
		padding:10px;

		.table{
			border-collapse:separate; 
			border-spacing:0 10px;
			margin-bottom: 0;
		}
		
		.base-info {
			tr>td{
				vertical-align: top;
				width:33%;
				border:none;
			}
		}
		h4{
			text-align:center;
			color:$icon-table-color;
		}
		.init-diagnosis{
			tr{
				margin:10px 0;
			}
			tr>td{
				vertical-align: top;
				border:none;
				&:first-child{
					white-space: nowrap;
					width:90px;
				}
				img{
					max-width:180px;
					max-height: 150px;
					margin:10px 5px;
				}
			}

		}
		.remote-diagnosis{
			margin-top: 10px;
			.diagnosis{
				position: relative;
				float:left;
				/* width:50%;
				&:nth-child(2n){
					border-left:1px solid $line-blue;
				} */
				.content{
					padding:5px 30px;
				}
				.supplement{
					position: absolute;
					top:-6px;
					right:-5px;
					padding:2px 4px;
				}
			}
			.diag-img{
				text-align:center;
				img{
					width:320px;
					/* height:300px; */
					max-height: 300px;
					margin:0px 10px 10px;
				}
			}
			.sign{
				text-align: right;
			}
		}
		
		.signPrint{
			display: none;
			z-index:2;
			position: absolute;
			right:10px;
			width:100%;
			bottom:-60px;
			height:60px;
			line-height: 60px;
			text-align: right;
		}

	}

	
	
	.sign{
		img{
			height:60px;
			width:120px;
		}
	}
	.media-gallery{
		.img-box{
			font-size: 14px;
			float:left;
			width:220px;
			border:1px solid #ccc;
			border-radius: 4px;
			margin:10px 20px;
			.imgwrap{
				width:100%;
				height:140px;
				overflow: hidden;
				img{
					width:100%;
					height:100%;
					-webkit-transition:1s;
					transition:1s;
					&:hover{
						-webkit-transform:scale(1.2);
						        transform:scale(1.2);
					}
				}
			}
			.zoomify{
				height:40px;
				line-height: 40px;
				text-align: center;
				background-color: lighten($icon-table-color,15);
				cursor: pointer;
				.glyphicon{
					font-size:22px;
					vertical-align: middle;
					color:#827c7c;
				}
			}
			.slice-info{
				padding:4px 10px;
				word-break: break-all;
			}
		}
	}
	h4{
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 20px;
	}
	.content-wrap{
		padding:10px 40px;
		label{
			width:70px;
			text-align:right;
		}
		textarea{
			width:318px;
		}
		.list{
			/* max-width: 500px; */
			display: inline-block;
			margin-bottom: 5px;
			margin-right: 10px;
			vertical-align: middle;
			a{
				position: relative;
				margin-right: 5px;
				display: inline-block;
				/* width:100%; */
				/* min-width: 100px; */
				padding:0 15px;
				color:$icon-table-color;
				cursor: pointer;
				i{
					position: absolute;
					right:-3px;
					top:6px;
					cursor: pointer;
					display: none;
				}
				&:hover{
					i{
						display: block;
					}

				}
			}
		}
		#upload-1{
			display: inline-block;
			vertical-align: middle;
		}
		.webuploader-pick{
			background-color: #fff;
			color:#333;
			border:1px solid #ccc;
			border-radius: 4px;
			padding:5px 10px;
		}
	}
	.diagnosis-warp{
		padding:10px;
	}
	.input-diagnosis{
		textarea{
				width:100%;
				min-height: 100px;
				border-radius: 4px;
		}
	}
	.upload-clip{
		
		.list{
			display:inline-block;
		}
		.img-clip{
			position: relative;
			display:inline-block;
			img{
				width:180px;
				height:150px;
				margin:10px 5px;
			}
			i{
				position: absolute;
				top:3px;
				right:0;
				color:$icon-table-color;
			}
		}
		
		#upload-1{
			position: relative;
			display:inline-block;
			width:180px;
			height: 150px;
			line-height: 150px;
			text-align:center;
			border-radius: 4px;
			margin:10px 5px;
			border:1px solid #ccc;
			vertical-align: middle;
			cursor: pointer;
			input{
				width:1px;
				height:1px;
				opacity:0;
			}
		}
	}
	
}
.preview-modal-1 {
	.record-content{
		.base-info {
			tr>td{
				width:25%;
			}
		}
	}
}
.affix-list{
	padding:5px 20px;
	.affix-item{
		.iconfont{
			font-size: 20px;
		}
		a{
			color:$icon-table-color;
		}

	}
}
.medical-modal-1,.return-modal-1,.supplement-modal-1{
	.modal-dialog{
		top:120px;
	}
	.details{
		&+.details{
			margin-top: 20px;
		}
		width:90%;
		margin:0 auto;
		&>div{
			&:first-child{
				width:20%;
			}
			&:last-child{
				min-height:100px;
				width:80%;
				border:1px solid #ccc;
				border-radius: 4px;
			}
		}
		textarea{
			width:100%;
			height:100px;
		}
	}
}
.alert-modal-1{
	.modal-footer{
	 padding-top: 0;
	 padding-bottom: 0;
	 border:none;
	 text-align: center;
	}
	.modal-body{
		/* min-height: 80px; */
		padding:40px 30px;
		text-align: center;
	}
}
.btn-hospital{
	background-color: #3f9cec;
	border-color: #3f9cec;
	color:#fff;
	&:hover{
		color:#fff;
		background-color:darken($icon-table-color,10);
	}
}
.fix-size{
	.modal-dialog{
		top:120px;
	}
}
.no-border{
	border:none !important;
}
.advice-modal-1{
	.modal-body{
		padding:0;
	}
	.details{
		&+.details{
			margin-top: 20px;
		}
		width:100%;
		&>div{
			width:47%;
			padding:10px;
		}
		.title{
			background-color:lighten($icon-table-color,10);
			color:#fff;
			height:30px;
			line-height:30px;
			padding: 0 10px;
			margin-bottom: 10px;
		}
		.table{
			th{
				text-align:center;
			}
			text-align: center;
		}
		.table-nav{
			text-align: center;
			position: relative;
			a{
				padding:0 10px;
				color:$icon-table-color;
				text-decoration: underline;
			}
			.index{
				position: absolute;
				top:0;
				right:10px;
			}
		}
		.del-advice{
			color:$icon-table-color;
			cursor: pointer;
			&:hover{
				color:darken($icon-table-color,10)
			}
		}
		.del-all{
			color:$icon-table-color;
			cursor: pointer;
			&:hover{
				color:darken($icon-table-color,10)
			}
		}
	}
}
/* medical-record.html user-center.html end */

/* validate form */
.input-group{
	position: relative;
	.Validform_checktip{
		font-size: 12px;
		position: absolute;
		top:100%;
		left:0;
	}
	.Validform_wrong{
		color:red;
	}
}
.Validform_checktip.Validform_wrong{
	color:red;
	font-size: 12px;
	white-space:nowrap;
}
/* validate form end */

/* image viewer start */
.openseadragon{
	position: relative;
	width: 100%;
	height: 600px;
	border:1px solid #e8e8e8;
	&.fullpage{
		margin-top: 60px;
	}
}
.viewer-toolbar        {
	width:      100%;
	border:     none;
	color:      #333;
	padding:    4px;
	background-color: transparent;
	
	&.fullpage{
		width:      100%;
		border:     none;
		position:   fixed;
		z-index:    999999;
		left:       0;
		top:        0;
		background-color: #ccc;
	}
}
.viewer-button{
	.iconfont{
		font-size: 25px;
	}
	display: inline-block;
	text-decoration: none;
	text-align: center;
	font-size: 12px;
	color:#555;
	width:60px;
	height:52px;
	&.small{
		width:30px;
	}
	&.zoomX{
		width:40px;
		
		span{
			margin:10px 5px;
			vertical-align: text-bottom;
			width:28px;
			line-height: 30px;
			border:1px solid #ccc;
			&:hover{
				border-color: $icon-table-color;
			}
		}
	}
	span{
		display: inline-block;
		width:100%;
	}
	&:hover{
		color:$icon-table-color;
	}
	&.active{
		color:$icon-table-color;
		span{
			border-color: $icon-table-color;
		}
	}
}
.fullpage {
	.navigator{
		margin-top: 60px!important;
	}
	.viewer-affix-box{
		margin-top: 60px!important;
	}
}
.highlight{
	background-color: red;
	opacity: 0.3;
	outline: 12px auto #0A7EbE;
}
.viewer-affix-box{
	position:absolute;
	display:none;
	right:10px;
	top:10px;
	z-index:2;
	.imgWrap{
		background-color: #fff;
		border-radius: 4px;
		border:1px solid $border-gray;
		width:180px;
		line-height:180px;
		margin-bottom:20px;
		&.active{
			border:1px solid $icon-table-color;
			-webkit-box-shadow: 1px 1px 1px $icon-table-color;
			        box-shadow: 1px 1px 1px $icon-table-color;
		}
		&.small{
			position: relative;
			width:120px;
			line-height: 120px;
		}
		img{
			width:100%;
			vertical-align: middle;
		}
	}
	.infoWrap{
		position: relative;
		background-color: #fff;
		border-radius: 4px;
		width:180px;
		border:1px solid $border-gray;
		.info-header{
			position: relative;
			color:$icon-table-color;
			padding:10px;
		}
		.info-body{
			display: none;
			max-height: 400px;
			overflow-y:auto; 
			word-break: break-all;
			padding:10px;
			&.active{
				display: block;
			}
		}
		.info-footer{
			position: absolute;
			bottom:0;
			right:0;
		}
	}

}
#slice-relative-viewer{
	max-height: 560px;
	overflow-y: auto;
	img{
		cursor: pointer;
	}
}
#measure-mark-viewer{
	left:222px;
	right:auto;
	width:auto;
	border:1px solid #ccc;
}
#anyRotate{
	position:relative;
	top:-10px;
	height:40px;
	width:45px;
	margin:0 10px 0 5px;
	border:1px solid #e8e8e8;
	text-align: center;
}
#screenshot-viewer{
	width:600px;
	height:300px;
	top:50%;
	left:50%;
	-webkit-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
	
	.screenshot-preview{
		width:400px;
		height:302px;
		border:1px solid $border-blue;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		background-color: #fff;
		overflow: hidden;
	}
	.infoWrap{
		border:none;
		border: 1px solid $border-blue;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		width:198px;
		height:302px;
		label{
			margin-left: 10px;
			font-weight: normal;
			cursor:pointer;
		}
	}

}
#tips-viewer{
	top:50%;
	left:50%;
	right:auto;
	-webkit-transform:translate3d(-50%,-50%,0);
	        transform:translate3d(-50%,-50%,0);
	.tips-wrap{
		padding:10px;
		display: inline-block;
		background-color: #fff;
		text-align:center;
		font-size: 12px;
		border-radius: 4px;
	}
}
#ppm-viewer{
	
	top:auto;
	right:auto;
	bottom:-20px;
	left:-16px;
	cursor:pointer;
	.ppm-config{
		display: none;
		&>div{
			height: 30px;
		}
		background-color: rgba(0, 0, 0, .7);
		position: absolute;
		top:-100px;
		padding:10px 10px 0;
		// width:220px;
		border-radius: 4px;
		label{
			color:#fff;
			font-weight: normal;
			min-width:70px;
			text-align: right;
		}
		input{
				width:100px;
				border-radius: 4px;
				margin-left: 10px;
				border:1px solid $icon-table-color;
		}
		.apply-ppm-config{
			margin:0;
		}
		
	}
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
#sequence-viewer{
	position: absolute;
	top:auto;
	right:auto;
	bottom: -20px;
	left:10px;
	.icon-back-left,.icon-front-right{
		cursor:pointer;
	}
	.sequence-index{
		margin-left: 8px;
	}
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
#medical-record-viewer {
	img{
		max-width: 180px;
	}
}
.icon-hot-point{
	color:rgba(33, 207, 243,0);
	&:hover{

		color:rgba(33, 207, 243,0.8);
	}
}
#image-rotate-viewer{
	border:1px solid #ccc;
	right:auto;
	left:200px;
	.slider.slider-horizontal{
		margin-bottom: 19px;
	}
}
.image-viewer-modal .modal-dialog{
	width:900px;
}
/* 暂时隐藏条形码相关内容（到时需使用直接注释即可） start */
#slice-info-viewer{
	.imgWrap{
		display: none;
	}
}
#screenshot-viewer .infoWrap .info-body>p:first-child{
	display: none;
}
/* 暂时隐藏条形码相关内容（到时需使用直接注释即可） end */

/* image viewer end */

/* data-timepicker start*/
.bootstrap-datetimepicker-widget.dropdown-menu{
	z-index:3;
}
/* data-timepicker end*/


/* user-center.html start */
.user-manage-style{
	width:1000px;
	.form-group{
		width:302px;
		.form-control{
			width:170px;
			&.date-input {
				width: 131px;
			}
		}
	}
	.content-wrap{
		textarea{
			width:776px;
			height: 70px;
			resize: none;
		}
	}
	h4{
		cursor: pointer;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}
	
}
/* user-center.html end */


.width25{
	td{
		width:25%!important;
	}
}

@media print{
	.medical-record{
		border:none;
	}
	.main_container {
		padding-top: 0;
	}
	.record-head{
		display: none;
	}
	.fix-center-nav{
		display: none;
	}
	.record-content{
		h4{
			font-size: 20px;
		}
		min-height: 1000px;
	}
	.sign{
		display: none;
	}
	.signPrint{
		display: block !important;
	}
}

.slider-handle{
	background:$icon-table-color;
}