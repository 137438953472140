@import "color";
@mixin border-t-blue{
    border-top:1px solid $line-blue;
}
@mixin border-b-blue{
    border-bottom:1px solid $line-blue;
}
@mixin border-b-gray{
    border-bottom:1px solid $line-gray;
}
