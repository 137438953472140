$navL-bg-color:#ceeafb;
$navL-slec-color:#438fad;
$navL-gradient-from:#72bdd4;
$navL-gradient-from:#337a96;
$bg-color:#fff;
$line-blue:#3498db;
$line-gray:#ccc;
$icon-table-color:#3f9cec;
$font-blue:#3f9cec;
$bg-gray:#eeebeb;
$border-gray:#ccc;
$border-blue:#3498db;




